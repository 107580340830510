<template>
  <section>
    <form action="" @submit.prevent="login">
      <MDBContainer
        fluid
        class="position-relative d-flex justify-content-center"
      >
        <MDBContainer class="position-absolute">
          <MDBCard class="p-3 rounded-5 shadow-1-strong">
            <MDBCardBody>
              <MDBCardTitle class="mb-3"> {{ $t("login") }} </MDBCardTitle>

              <MDBCardText>
                <div class="row">
                  <div class="col-md-5 mb-3">
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1">
                        <MDBIcon icon="user" class="trailing"></MDBIcon>
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('email')"
                        :aria-label="$t('email')"
                        v-model="userData.email"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                  <div class="col-md-5 mb-3">
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1">
                        <MDBIcon icon="key" class="trailing"></MDBIcon>
                      </span>
                      <input
                        type="password"
                        class="form-control"
                        :placeholder="$t('password')"
                        :aria-label="$t('password')"
                        v-model="userData.password"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <button
                      type="submit"
                      id="sign-in-button"
                      class="btn btn-success w-100 sign-in-button"
                    >
                      تسجيل الدخول
                    </button>
                    <router-link to="/forgot-password">
                      <a
                        to="/forgot-password"
                        class="text-success"
                        href="/forgot-password"
                      >
                        <small> هل نسيت كلمة المرور؟ </small></a
                      >
                    </router-link>
                  </div>
                </div>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
      </MDBContainer>
    </form>
  </section>
</template>

<script>
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBInput,
  MDBIcon,
} from "mdb-vue-ui-kit";
export default {
  components: {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBInput,
    MDBIcon,
  },
  data() {
    return { userData: { email: "", password: "" } };
  },

  methods: {
    async login() {
      if (this.userData.email != "" && this.userData.password != "")
        try {
          this.$store.dispatch("login", this.userData);

          this.$router.push("/");
        } catch (err) {
          let timerInterval = this.$swal({
            title: "فشل تسجيل الدخول",
            timer: 2000,
            timerProgressBar: true,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        }
    },
  },
};
</script>
<style lang="sass" scoped>
.card
  z-index: 3
  position: relative
  top: -18vh
  background: #fff

  &-title
    text-align: start

@media (min-width: 576px)
  .card
    top: -17vh

@media (min-width: 768px)
  .card
    top: -14vh

@media (min-width: 992px)
  .card
    top: -12vh
</style>