export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url"])},
  "add_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة صورة"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم"])},
  "ar_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar description"])},
  "en_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en description"])},
  "kr_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kr description"])},
  "application_completed_and_awaiting_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتمل الطلب وينتظر التأكيد"])},
  "number_of_trees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاشجار"])},
  "set_an_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعداد طلب"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "delete_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الشعار"])},
  "do_you_want_to_delete_the_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حذف الشعار"])},
  "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخلفية"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "add_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة شعار"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعار"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العدد"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
  "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الادوات"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
  "delete_slides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف السلايد"])},
  "Do_you_want_this_slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد السلايد"])},
  "You_are_logged_in_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تسجيل الدخول بنجاح"])},
  "The_code_has_been_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ارسال الكود بنجاح"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "send_verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال رمز التحقق"])},
  "enter_the_verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رمز التحقق"])},
  "The_number_of_trees_planted_in_the_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاشجار المزروعة ضمن الحملة"])},
  "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير الحالة"])},
  "waiting_for_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار التاكيد"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التاكيد"])},
  "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الالغاء"])},
  "all_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الطلبات"])},
  "adjust_slider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضبط السلايدر"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
  "plant_a_tree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ازرع شجرة"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "green_raq_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حملة عراق خضراء"])},
  "number_of_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المشاركين :"])},
  "the_remaining_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت المتبقي :"])},
  "campaign_supporters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داعمي الحملة"])},
  "designed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التصميم بواسطة EAST IQ"])}
}