<template>
  <MDBContainer class="py-2" fluid>
    <MDBContainer>
      <div class="d-flex justify-content-between align-center">
        <div>
          <a
            class="social-link"
            href="https://www.facebook.com/2030.greeniraq"
            target="_blank"
            ><i class="fab fa-facebook fa-2x px-2"></i
          ></a>
          <a
            class="social-link"
            href="https://instagram.com/2030.greeniraq"
            target="_blank"
            ><i class="fab fa-instagram fa-2x px-2"></i
          ></a>
        </div>
        <div class="d-flex align-center">
          <a href="//east-iq.com"><bdi v-text="$t('designed_by')"></bdi></a
          ><span> &copy; 2022</span>
        </div>
      </div>
    </MDBContainer>
  </MDBContainer>
</template>

<script>
import { MDBContainer } from "mdb-vue-ui-kit";

export default {
  components: {
    MDBContainer,
  },
};
</script>

<style lang="sass" scoped>
.container-fluid
  background-color: #0b3726
a,span,.fab
  color: #f9fbe7
  &:hover
    color: #ccc
.d-flex
  align-items: center
</style>