import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { auth } from "@/firebase/init";
import { onAuthStateChanged } from "firebase/auth";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import i18n from "./i18n";

// import { currentUser } from "firebase/auth";
store.dispatch("getSlides").then(() => {
  onAuthStateChanged(auth, (user) => {
    store.dispatch("getUsersCol");
    if (user) {
      store.commit("setUser", user);
    } else {
      store.dispatch("logout");
    }
  });
  setTimeout(() => {
    createApp(App)
      .use(i18n)
      .use(store)
      .use(router)
      .use(VueSweetalert2)
      .mount("#app");
  }, 1100);
});
