<template>
  <Carousel :autoplay="3000" :wrap-around="true" :transition="750">
    <Slide v-for="slide in slides" :key="slide.uri">
      <div class="carousel__item" v-if="slide.uri">
        <div
          class="slider-image"
          :style="{ background: [`url(${slide.uri})`] }"
        >
          <h1 v-if="$i18n.locale == 'en'" v-text="slide.en_description"></h1>
          <h1
            v-else-if="$i18n.locale == 'ar'"
            v-text="slide.ar_description"
          ></h1>
          <h1
            v-else-if="$i18n.locale == 'kr'"
            v-text="slide.kr_description"
          ></h1>
        </div>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default {
  name: "HomeSlider",
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        autoplay: 1000,
        slides: [],
      },
    };
  },

  beforeCreate() {
    this.slides = this.$store.state.slides;
  },
  async mounted() {
    // document.querySelector(".slider-image").style.height =
    //   screen.height.toString;
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
};
</script>
<style lang="sass">
.carousel
  overflow-x: hidden

  &__prev,

  &__next
    background-color: rgba(255, 255, 255, 0.3)
    width: 2rem
    height: 2rem

  &__prev
    left: 1.25rem

  &__next
    right: 1.25rem

  &__icon
    color: #fff

  &__item
  .slider-image
    width: 100vw
    min-height: 100vh
    background-size: cover !important
    background-repeat: no-repeat !important
    background-position: center center !important
    display: flex
    justify-content: center
    align-items: center
    cursor: grab
    &::after
      content: ""
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 1
      background-color: rgba(0, 0, 0, 0.6)

    h1
      font-weight: bolder
      font-size: 2.5rem
      width: 75%
      word-wrap: break-word
      color: #fff
      z-index: 2
      cursor: auto

@media (min-width: 576px)
  .carousel__prev,
  .carousel__next
    width: 2.5rem
    height: 2.5rem

  .carousel__prev
    left: 1.5rem

  .carousel__next
    right: 1.5rem

  .slider-image h1
    font-size: 4rem
    width: 74%

@media (min-width: 768px)
  .carousel__prev,
  .carousel__next
    width: 2.5rem
    height: 2.5rem

  .carousel__prev
    left: 1.75rem

  .carousel__next
    right: 1.75rem

  .carousel__item .slider-image h1
    font-size: 4.75rem
    width: 70%

@media (min-width: 992px)
  .carousel__prev,
  .carousel__next
    width: 3rem
    height: 3rem

  .carousel__prev
    left: 2rem

  .carousel__next
    right: 2rem

  .carousel__item .slider-image h1
    font-size: 5.75rem
    width: 55%
</style>
