import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { auth } from "@/firebase/init";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: false },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/ForgotPasswordView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/plant-now",
    name: "plant-now",
    component: () => import("../views/PlantNowView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/all-orders",
    name: "all-orders",
    component: () => import("../views/AllOrdersView.vue"),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/campaign-supporters",
    name: "campaign-supporters",
    component: () => import("../views/CampaignSupporters.vue"),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/slides",
    name: "slides",
    component: () => import("../views/SlidesView.vue"),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const authenticated = auth.currentUser;
  let admin = null;
  if (authenticated) {
    admin =
      authenticated.phoneNumber == process.env.VUE_APP_ADMIN ? true : false;
  } else {
    admin = false;
  }

  if (requiresAdmin && admin) {
    return next();
  } else if (requiresAdmin && !admin) {
    return next("/");
  } else if (requiresAuth && authenticated) {
    return next();
  } else if (!requiresAuth && !authenticated) {
    return next();
  } else {
    if (to.fullPath != "/") {
      return next("/");
    }
    return next();
  }
});
export default router;
