import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDOUjSSXlAd0LOu65i8FsjHYvCg34OBD_s",
  authDomain: "green-iraq-e373c.firebaseapp.com",
  projectId: "green-iraq-e373c",
  storageBucket: "green-iraq-e373c.appspot.com",
  messagingSenderId: "343453703782",
  appId: "1:343453703782:web:9df32b0d587e8f776597a7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// init & export firestore service
export const db = getFirestore(app);
export const storage = getStorage(app);
// generate & export auth object
export const auth = getAuth();
