<template>
  <MDBContainer fluid id="about-campaign"
    ><MDBContainer class="py-5" style="margin-top: 6rem">
      <div class="row">
        <div class="pl-3">
          <div>
            <p
              id="title"
              class="mb-5 text-capitalize"
              v-text="$t('green_raq_campaign')"
            ></p>
            <div class="d-flex justify-content-center align-items-center mb-4">
              <h1
                class="text-end px-2 text-capitalize"
                v-text="$t('number_of_participants')"
              ></h1>
              <span id="participants"
                ><h1 class="p-2">{{ cUsers }}</h1></span
              >
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <h1
                class="text-end px-2 text-capitalize"
                v-text="$t('the_remaining_time')"
              ></h1>
              <span>
                <Countdown
                  :showMonths="true"
                  :deadlineDate="new Date('2030-01-01')"
                  :labels="settings[$i18n.locale]"
                  mainColor="#fff"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <SvgMap />
    </MDBContainer>
  </MDBContainer>
</template>

<script>
import { MDBContainer } from "mdb-vue-ui-kit";
import { Countdown } from "vue3-flip-countdown";
import SvgMap from "@/components/SvgMap";

export default {
  data() {
    return {
      settings: {
        ar: {
          months: "شهور",
          days: "ايام",
          hours: "ساعات",
          minutes: "دقائق",
          seconds: "ثواني",
        },
        en: {
          months: "Months",
          days: "Days",
          hours: "Hours",
          minutes: "Minutes",
          seconds: "Seconds",
        },
        kr: {
          months: "مانگەکان",
          days: "ڕۆژەکان",
          hours: "کاتژمێرەکان",
          minutes: "خولەک",
          seconds: "چرکە",
        },
      },
    };
  },
  computed: {
    cUsers() {
      return this.$store.state.usersCol.length;
    },
  },
  components: {
    MDBContainer,
    Countdown,
    SvgMap,
  },
  // mounted() {
  //   console.log(this.$store.state.usersCol.length);
  // },
};
</script>
<style lang="sass" scoped>
#title
  font-size: 1.75rem
  color: #33691e
  background: -webkit-linear-gradient(#85bb5c, #333)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

h1
  color: #33691e
.flip-card__back-bottom-4digits
  width: fit-content !important

#participants h1
  background-color: #222
  color: #fff
  width: fit-content
  border-radius: .25rem

@media (min-width: 576px)

#title
  font-size: 2.25rem

@media (min-width: 768px)
#title
  font-size: 3.25rem

@media (min-width: 992px)
#title
  font-size: 4rem
</style>
