export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url"])},
  "delete_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete logo"])},
  "ar_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar description"])},
  "en_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en description"])},
  "kr_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kr description"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["done"])},
  "application_completed_and_awaiting_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application completed and awaiting confirmation"])},
  "number_of_trees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of trees"])},
  "set_an_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["set an order"])},
  "add_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add image"])},
  "do_you_want_to_delete_the_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the logo"])},
  "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["background"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])},
  "add_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add logo"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logo"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
  "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tools"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "delete_slides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete slides"])},
  "Do_you_want_this_slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want this slide"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "send_verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send verification code"])},
  "enter_the_verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the verification code"])},
  "The_code_has_been_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code has been sent successfully"])},
  "You_are_logged_in_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged in successfully"])},
  "The_number_of_trees_planted_in_the_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of trees planted in the campaign"])},
  "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change status"])},
  "waiting_for_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waiting for confirmation"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmed"])},
  "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canceled"])},
  "all_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All orders"])},
  "adjust_slider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjust slider"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "plant_a_tree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plant a tree"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "green_raq_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Iraq Campaign"])},
  "number_of_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of participants :"])},
  "the_remaining_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the remaining time :"])},
  "campaign_supporters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Supporters"])},
  "designed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designed by EAST IQ"])}
}