<template>
  <HomeHeader />
  <router-view />
  <Footer />
</template>
<script>
import HomeHeader from "@/components/HomeHeader";
import Footer from "@/components/Footer";
export default {
  components: {
    HomeHeader,
    Footer,
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@400;500;700;800&display=swap");
@import "mdb-vue-ui-kit/src/scss/index.free.scss";
$font-family-base: "IBM Plex Sans Arabic", Helvetica, Arial, sans-serif;
$my-primary-color: #33691e;

#app {
  font-family: "IBM Plex Sans Arabic", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #33691e;
  background-color: #f9fbe7 !important;
  overflow-x: hidden !important;
}
input {
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input-group span {
  border-radius: 0 0.25rem 0.25rem 0 !important;
  color: var(--my-primary-color);
}
</style>
