<template>
  <header>
    <!-- Navbar -->
    <MDBNavbar expand="lg" light bg="white" position="top">
      <div class="container d-flex">
        <router-link to="/" v-mdb-ripple>
          <MDBNavbarBrand v-mdb-ripple
            ><img src="@/assets/img/logo.png" alt=""
          /></MDBNavbarBrand>
        </router-link>
        <div class="d-flex">
          <div class="lang-dropdown">
            <select @change="setLang($event)" v-model="$i18n.locale">
              <option
                v-for="(lang, i) in languageArray"
                :key="`lang${i}`"
                :value="lang"
              >
                {{ lang.toUpperCase() }}
              </option>
            </select>
          </div>

          <MDBNavbarToggler
            @click="collapse1 = !collapse1"
            target="#navbarSupportedContent"
          ></MDBNavbarToggler>
        </div>

        <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
          <MDBNavbarNav left class="mb-2 mb-lg-0">
            <MDBNavbarItem to="/">{{ $t("home") }}</MDBNavbarItem>

            <MDBNavbarItem v-if="isLoggedIn" to="/plant-now">
              {{ $t("plant_a_tree") }}
            </MDBNavbarItem>
            <MDBDropdown v-if="isAdmin" class="nav-item" v-model="dropdown">
              <MDBDropdownToggle
                class="nav-link text-white"
                @click="dropdown = !dropdown"
              >
                {{ $t("dashboard") }}
              </MDBDropdownToggle>

              <MDBDropdownMenu>
                <MDBDropdownItem to="/all-orders" href="all-orders">
                  {{ $t("all_orders") }}
                </MDBDropdownItem>
                <MDBDropdownItem
                  to="/campaign-supporters"
                  href="campaign-supporters"
                >
                  {{ $t("campaign_supporters") }}
                </MDBDropdownItem>
                <MDBDropdownItem to="/slides" href="slides">{{
                  $t("adjust_slider")
                }}</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>

            <router-link v-if="!isLoggedIn" class="ml-3" to="/register">
              <MDBBtn color="success"> {{ $t("login") }}</MDBBtn>
            </router-link>
            <MDBBtn
              v-if="isLoggedIn"
              @click.prevent="$store.dispatch('logout', {})"
              color="danger"
            >
              {{ $t("logout") }}
            </MDBBtn>
          </MDBNavbarNav>
        </MDBCollapse>
      </div>
    </MDBNavbar>
    <!-- Navbar -->
  </header>
</template>

<script>
import {
  mdbRipple,
  MDBBtn,
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBContainer,
} from "mdb-vue-ui-kit";
import { ref } from "vue";
export default {
  components: {
    MDBBtn,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBContainer,
  },
  directives: {
    mdbRipple,
  },
  data() {
    return {
      activeRoute: "",
      languageArray: ["en", "ar", "kr"],
    };
  },

  setup() {
    const collapse1 = ref(false);
    const dropdown = ref(false);
    return {
      collapse1,
      dropdown,
    };
  },
  created() {
    this.activeRoute = this.$route.path;
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.user;
    },
    isAdmin() {
      if (this.$store.state.user) {
        return this.$store.state.user.phoneNumber == process.env.VUE_APP_ADMIN;
      }
      return false;
    },
  },
  methods: {
    isActiveRoute(txt) {
      if (this.activeRoute == txt) {
        return "active";
      }
      return;
    },
    setLang(event) {
      localStorage.setItem("lang", event.target.value);
      window.location = "/";
    },
  },
};
</script>
<style lang="sass" scoped>
nav
  transition: all .5s ease
  img
    width: 7rem
.dropdown-menu li
  cursor: pointer

.lang-dropdown select
  cursor: pointer
</style>