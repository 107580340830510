<template>
  <MDBContainer class="py-4" fluid>
    <MDBContainer>
      <p id="title" class="" v-text="$t('campaign_supporters')"></p>

      <a v-for="sponsor in sponsors" :href="sponsor.url" :key="sponsor.docID">
        <img class="mx-1" :src="sponsor.uri" />
      </a>
    </MDBContainer>
  </MDBContainer>
</template>

<script>
import { MDBContainer } from "mdb-vue-ui-kit";

export default {
  components: {
    MDBContainer,
  },
  async beforeCreate() {
    this.sponsors = await this.$store.dispatch("fetchData", "supporters");
  },
  data() {
    return {
      sponsors: [],
    };
  },
};
</script>

<style lang="sass" scoped>
.container-fluid
  background-color: #fff
#title
  font-size: 1.75rem
  color: #33691e
  background: -webkit-linear-gradient(#85bb5c, #333)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
img
  width: 11rem
  filter: grayscale(1)
  -webkit-transition: all 250ms
  -moz-transition: all 250ms
  -o-transition: all 250ms
  transition: all 250ms
  cursor: pointer
  &:hover
    filter: grayscale(0)

@media (min-width: 576px)

#title
  font-size: 2.25rem
img
  width: 12rem

@media (min-width: 768px)
#title
  font-size: 3.25rem
img
  width: 13.5rem

@media (min-width: 992px)
#title
  font-size: 4rem
img
  width: 15rem
</style>