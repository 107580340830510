export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماڵەوە"])},
  "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url"])},
  "delete_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لۆگۆ بسڕەوە"])},
  "ar_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar description"])},
  "en_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en description"])},
  "kr_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kr description"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئەنجام درا"])},
  "application_completed_and_awaiting_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کاربەرنامە تەواو بوو و چاوەڕێی دووپاتکردنەوە دەکات"])},
  "number_of_trees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی دارەکان"])},
  "set_an_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دانانی فەرمانێک"])},
  "add_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زیادکردنی وێنە"])},
  "do_you_want_to_delete_the_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دەتەوێت لۆگۆکە بسڕیتەوە"])},
  "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باکگراوند"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وەسف"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەڵگرتن"])},
  "add_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لۆگۆ زیاد بکە"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لۆگۆ"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناو"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناونیشان"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارە"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دۆخ"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەروار"])},
  "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئامێرەکان"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["چوونەژوورەوە"])},
  "delete_slides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلایدەکان بسڕەوە"])},
  "Do_you_want_this_slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئایا ئەم سلایدەت دەوێت"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئیمەیڵ"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهێنوشە"])},
  "send_verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کۆدی سەلماندن بنێرە"])},
  "enter_the_verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کۆدی سەلماندن تێبنووسە"])},
  "The_code_has_been_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کۆدەکە بەسەرکەوتوویی نێردراوە"])},
  "You_are_logged_in_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تۆ بە سەرکەوتوویی تۆمارت کردووە"])},
  "The_number_of_trees_planted_in_the_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی ئەو دارانەی لەهەڵمەتەکەدا چێندرابوون"])},
  "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["گۆڕینی بار"])},
  "waiting_for_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["چاوەڕوانی دووپاتکردنەوە"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دووپاتکرایەوە"])},
  "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەڵوەشایەوە"])},
  "all_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەموو داواکاریەکان"])},
  "adjust_slider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلایدەر ڕێک بخە"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داشبۆرد"])},
  "plant_a_tree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دارێک بچێنن"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["چوونەژوورەوە"])},
  "green_raq_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەڵمەتی سەوزی عێراق"])},
  "number_of_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی بەشداربووان :"])},
  "the_remaining_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کاتی ماوە :"])},
  "campaign_supporters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لایەنگرانی کەمپین"])},
  "designed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دیزاین کراوە لەلایەن EAST IQ"])}
}