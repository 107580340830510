<template>
  <HomeSlider />
  <!-- <LoginForm v-if="!isLoggedIn" /> -->
  <AboutCampaign />
  <Sponsors />
</template>
<script>
import HomeSlider from "@/components/HomeSlider";
import LoginForm from "@/components/LoginForm";
import AboutCampaign from "@/components/AboutCampaign";
import Sponsors from "@/components/Sponsors";

export default {
  components: {
    HomeSlider,
    LoginForm,
    AboutCampaign,
    Sponsors,
  },
  data() {
    return {
      code: null,
    };
  },
  async beforeCreate() {
    // console.log(await this.$store.dispatch("fetchData", "users"));
    //console.log(process.env.VUE_APP_ADMIN);
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.user;
    },
  },
};
</script>