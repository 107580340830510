import { createStore } from "vuex";
import { auth, db } from "@/firebase/init";
import { storage } from "@/firebase/init";
import { ref, listAll } from "firebase/storage";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  signInWithEmailAndPassword,
  signOut,
  // RecaptchaVerifier,
  // signInWithPhoneNumber,
  // updatePhoneNumber,
  PhoneAuthProvider,
} from "firebase/auth";
import { collection, addDoc, getDocs, setDoc, doc } from "firebase/firestore";

let store = createStore({
  state: {
    user: null,
    verificationId: null,
    usersCol: [],
    slides: [],
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setVerificationId(state, payload) {
      state.verificationId = payload;
    },
    setUsersCol(state, payload) {
      state.usersCol = payload;
    },
    setSlides(state, payload) {
      state.slides = payload;
    },
  },

  actions: {
    async signup(context, { email, pass, name, phone, age }) {
      try {
        const credential = await createUserWithEmailAndPassword(
          auth,
          email,
          pass
        );
        const actionCodeSettings = {
          url: `http://green.web-app-demo.xyz/`,
          handleCodeInApp: false,
        };
        updateProfile(credential.user, {
          displayName: name,
        });
        await sendEmailVerification(credential.user, actionCodeSettings);
        await addDoc(collection(db, "users"), {
          id: credential.user.uid,
          name: name,
          email: email,
          age: age,
          phone: phone,
        });
        // const recaptchaVerifier = new RecaptchaVerifier(
        //   "recaptcha",
        //   {
        //     size: "small",
        //     callback: async (response) => {},
        //   },
        //   auth
        // );
        // console.log(window.recaptchaVerifier);
        const provider = new PhoneAuthProvider(auth);
        const verificationId = await provider.verifyPhoneNumber(
          phone,
          window.recaptchaVerifier
        );
        context.commit("setVerificationId", verificationId);
        // console.log(response);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();

        // const phoneNumber = "+201026369598";
        // upda;
        // const appVerifier = window.recaptchaVerifier;
        // signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        //   .then((confirmationResult) => {
        //     window.confirmationResult = confirmationResult;
        //   })
        //   .catch((error) => {});

        // console.log(auth.currentUser);

        // auth.updateUser(auth.currentUser.uid, {
        //   phoneNumber: "+201026369598",
        // });
        // window.recaptchaVerifier = new RecaptchaVerifier(
        //   "sign-in-button",
        //   {
        //     size: "invisible",
        //     callback: (response) => {
        //       // reCAPTCHA solved, allow signInWithPhoneNumber.
        //       onSignInSubmit();
        //     },
        //   },
        //   auth
        // );
        // const phoneNumber = "+201026369598";
        // const appVerifier = window.recaptchaVerifier;
        // signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        //   .then((confirmationResult) => {
        //     window.confirmationResult = confirmationResult;
        //   })
        //   .catch((error) => {});

        await signOut(auth);
      } catch (error) {
        console.log(error);
        return "فشل انشاء الحساب";
      }
    },
    async login(context, { email, password }) {
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;

          /*  // Obtain the verificationCode from the user.
            const phoneCredential = PhoneAuthProvider.credential(
              verificationId,
              verificationCode
            );
            await updatePhoneNumber(user, phoneCredential); */

          if (userCredential.user.emailVerified) {
            context.commit("setUser", userCredential.user);
            alert("تم تسجيل الدخول بنجاح");
          } else {
            context.commit("setUser", null);
            await signOut(auth);
            alert("برجاء تاكيد البريد الالكتروني");
          }
        })
        .catch((error) => {
          context.commit("setUser", null);
          alert("فشل تسجيل الدخول");
        });
    },
    async logout(context) {
      await signOut(auth);
      context.commit("setUser", null);
      context.commit("setVerificationId", null);
    },
    async getUsersCol(context) {
      const usersCol = await collection(db, "orders");
      const userSnapshot = await getDocs(usersCol);
      context.commit(
        "setUsersCol",
        userSnapshot.docs.map((doc) => doc.data())
      );
    },
    async fetchData(context, colName) {
      const col = collection(db, colName);
      const snapshot = await getDocs(col);
      // context.commit(
      //   "setUsersCol",
      //   userSnapshot.docs.map((doc) => doc.data())
      // );
      return snapshot.docs.map((doc) => {
        let data = doc.data();
        data.docID = doc.id;
        return data;
      });
    },
    async addData(context, { colName, data }) {
      const newRef = doc(collection(db, colName));
      const res = await setDoc(newRef, data);
    },
    async getSlides(context) {
      context.commit(
        "setSlides",
        await context.dispatch("fetchData", "slides")
      );
    },
  },
  modules: {},
});

export default store;
